<template>
    <b-container>
      <b-row cols-lg="2">
        <b-col v-for="(event, key) in getShop().events" :key="key">
          <b-card class="cursor-pointer mb-4" @click="goToEvent(event)">
            <font-awesome-icon icon="fa-solid fa-chevron-right" class="float-right mt-2 text-muted" />
            <div class="fs-bold title fs-5">
              {{ event.name }}<template v-if="event.location"> - {{ event.location.name }}</template>
            </div>
            <div class="text-muted">
              <span>
                <font-awesome-icon icon="fa-solid fa-calendar" class="mr-1" />
                <template v-if="event.is_multiday_ticket">
                  Meerdere datums
                </template>
                <template v-else>
                  <template v-if="isSameDayEvent(event)">
                    {{ event.slots[0].start_at|formatDate }}
                  </template>
                  <template v-else>
                    {{ event.slots[0].start_at|formatDate }} - {{ event.slots[0].end_at|formatDate }}
                  </template>
                </template>
              </span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>
  
<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'

  export default {
    metaInfo: {
        title: 'Tickets',
    },
    components: {},
    data() {
        return {
          shop: {}
        }
    },
    computed: {},
    methods: {
      ...mapActions(useShopStore, ['getShop']),
      isSameDayEvent(event) {
        let d1 = new Date(event.slots[0].start_at);
        let d2 = new Date(event.slots[0].end_at);
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
      },
      goToEvent(event) {
        this.$router.push('/' + this.getShop().organisation.slug + '/' + event.slug + '-' + event.slug_hash);
      },
    },
    created() {}
  }
</script>

<style scoped>
  .title{
    color:var(--primary-color);
  }
</style>